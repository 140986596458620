<template>
  <HXContentCard title="Nuevo Reporte" :toBack="{ name: 'Reportes' }">
    <form @submit.prevent="saveData">
      <div class="row p-4">
        <!-- empresa -->
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            <label for="empresa" class="form-label">
              <em class="text-danger font-weight-bolder">Empresa:</em>
            </label>
            <!-- vue-select -->
            <div style="background: transparent; padding: 0rem">
              <v-select
                v-model="reporte.companyId"
                class="style-chooser"
                :reduce="(value) => value.id"
                :options="listaEmpresas"
                :get-option-label="(option) => option.nombre"
                placeholder="-- seleccionar empresa --"
              >
                <!-- required -->
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!reporte.companyId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <!-- sin resultados en la busquedad -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontraron resultados para
                    <em class="text-danger">{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >No hay empresas para mostrar.</em
                  >
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- nivel reporte -->
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            <label for="nivelReporte" class="form-label">
              <em class="text-danger font-weight-bolder">Nivel de Reporte:</em>
            </label>
            <!-- vue-select -->
            <div style="background: transparent; padding: 0rem">
              <v-select
                v-model="reporte.reportingLevel"
                class="style-chooser"
                :reduce="(value) => value.id"
                :options="listaNiveles"
                :get-option-label="(option) => option.text"
                placeholder="-- seleccionar nivel --"
              >
                <!-- required -->
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!reporte.reportingLevel"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <!-- sin resultados en la busquedad -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontraron resultados para
                    <em class="text-danger">{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >No hay niveles para mostrar.</em
                  >
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- url reporte -->
        <div class="col-12 col-lg">
          <label for="urlReporte" class="form-label">
            <em class="text-danger font-weight-bolder">Url Reporte:</em>
          </label>
          <b-form-textarea
            style="resize: none"
            v-model="reporte.url"
            placeholder="Ingrese la URl de reporte..."
            required
            rows="7"
            minlength="10"
            maxlength="500"
            @input="validateURL"
          ></b-form-textarea>
          <span v-if="showError" class="text-warning h4 font-italic"
            >Ingrese una URL válida.</span
          >
        </div>
        <!-- descripcion reporte -->
        <div class="col-12 col-lg">
          <label for="descripcionReporte" class="form-label">
            <em class="text-danger font-weight-bolder">Descripción:</em>
          </label>
          <b-form-textarea
            style="resize: none"
            v-model="reporte.description"
            placeholder="Escriba una descripción..."
            required
            rows="7"
            minlength="10"
            maxlength="500"
          ></b-form-textarea>
        </div>
        <!-- btn -->
        <div class="col-12 d-flex justify-content-end mt-2">
          <vs-button animation-type="vertical">
            Guardar Reporte
            <template #animate> <i class="fas fa-floppy-disk"></i></template>
          </vs-button>
        </div>
      </div>
    </form>
  </HXContentCard>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
const Swal = require("sweetalert2");
export default {
  name: "DataTable",
  data() {
    return {
      listaEmpresas: [],
      showError: false,
      reporte: {
        companyId: null,
        reportingLevel: null,
        url: null,
        description: null,
      },
      listaNiveles: [
        { id: 1, text: "Nivel 1" },
        { id: 2, text: "Nivel 2" },
        { id: 3, text: "Nivel 3" },
        { id: 4, text: "Nivel 4" },
        { id: 5, text: "Nivel 5" },
        { id: 6, text: "Nivel 6" },
        { id: 7, text: "Nivel 7" },
        { id: 8, text: "Nivel 8" },
        { id: 9, text: "Nivel 9" },
        { id: 10, text: "Nivel 10" },
        { id: 11, text: "Nivel 11" },
        { id: 12, text: "Nivel 12" },
        { id: 13, text: "Nivel 13" },
        { id: 14, text: "Nivel 14" },
        { id: 15, text: "Nivel 15" },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getListEmpresas();
    this.$isLoading(false);
  },
  methods: {
    async saveData() {
      // console.log(this.reporte);
      await this.addPowerBI(this.reporte);
    },
    async getListEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        // console.log("list empresas... ", res);
        this.listaEmpresas = res;
      } catch (error) {
        this.listaEmpresas = [];
        console.log("err", error);
      }
    },
    async addPowerBI(powerBIreport) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "PowerBI/Create/",
          data: powerBIreport,
        });
        // console.log("powerBIreport created... ", res);
        if (res.data.data) {
          Swal.fire("Listo!", "Se ha guardado el reporte.", "success");
          this.initFormReport();
        } else {
          Swal.fire("Error!", `${res.data.msg}`, "error");
        }
      } catch (error) {
        console.log("err", error);
        Swal.fire(
          "Error!",
          "Ha ocurrido un error al guardar el reporte.",
          "error"
        );
      }
    },
    initFormReport() {
      for (let prop in this.reporte) {
        this.reporte[prop] = null;
      }
    },
    validateURL() {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      this.showError = !urlPattern.test(this.reporte.url);
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
